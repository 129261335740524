@use 'sass:map';
@use '@angular/material' as mat;
@import "solutions";

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .dot {
    margin-top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
  }

  .price-dot {
    @extend .dot;
    background: #{$price} !important;
  }

  .sale-price-dot {
    @extend .dot;
    background: #{$sale-price} !important;
  }

  .out-of-stock-dot {
    @extend .dot;
    background: #{$out-of-stock} !important;
  }

  .price-toggle.mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: #{$price} !important;
    --mdc-switch-selected-focus-handle-color: #{$price} !important;
    --mdc-switch-selected-hover-handle-color: #{lighten($price,10%)} !important;
    --mdc-switch-selected-pressed-handle-color: #{lighten($price,10%)} !important;
  }

  .sale-price-toggle.mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: #{$sale-price} !important;
    --mdc-switch-selected-focus-handle-color: #{$sale-price} !important;
    --mdc-switch-selected-hover-handle-color: #{lighten($sale-price,10%)} !important;
    --mdc-switch-selected-pressed-handle-color: #{lighten($sale-price,10%)} !important;
  }

  .out-of-stock-toggle.mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: #{$out-of-stock} !important;
    --mdc-switch-selected-focus-handle-color: #{$out-of-stock} !important;
    --mdc-switch-selected-hover-handle-color: #{lighten($out-of-stock,10%)} !important;
    --mdc-switch-selected-pressed-handle-color: #{lighten($out-of-stock,10%)}  !important;
  }
}
